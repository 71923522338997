import React, { useEffect } from 'react'
import axios from 'axios'
import useUserProfile from 'hooks/useUserProfile'
import Head from 'next/head'

const script = `!function(){function t(t,e){return function(){window.carrotquestasync.push(t,arguments)}}if("undefined"==typeof carrotquest){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.carrotquest.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.carrotquest={},window.carrotquestasync=[],carrotquest.settings={};for(var n=["connect","track","identify","auth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)carrotquest[n[a]]=t(n[a])}}(),carrotquest.connect("57072-bca8b049622936e74d8d38fd6a");`

const CarrotQuest = ({ hidden, onLoad }) => {
  const { uid, data: userProfile } = useUserProfile()

  useEffect(() => {
    if (hidden) {
      global.carrotquest.onReady(() => {
        global.carrotquest.removeChat()

        if (onLoad) {
          onLoad()
        }
      })
    }
  }, [])

  useEffect(() => {
    if (uid) {
      const auth = async () => {
        if (userProfile?.email) {
          global.carrotquest.identify({
            $name: userProfile?.name,
            $email: userProfile?.email,
            plan: userProfile?.plan?.id || 'free',
            occupation: userProfile?.survey?.occupation || '',
            subscription: userProfile?.plan?.hasActiveSubscription || '',
          })
        }

        const hash = await axios
          .post('/api/user/get-hash', { uid })
          .then((res) => res.data.hash)
        global.carrotquest.auth(uid, hash)
      }
      auth()
    }
  }, [uid, userProfile])

  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: script }}
      />
    </Head>
  )
}

export default CarrotQuest
